import React from 'react'
import {
  Container, Row, Col,
} from 'reactstrap'
import { Router } from '@reach/router'
import { useQueryParam, NumberParam } from 'use-query-params'
import Layout from '../components/Layout'
import Seo from '../components/seo'
import Hero from '../components/Hero'
import ButtonBack from '../components/ButtonBack'
import RegisterForm from '../components/RegisterForm'

import '../scss/page-single.scss'

const ContentSinglePage = () => {
  const [numberId] = useQueryParam('id', NumberParam)

  return (
    <Layout>
      <Seo title="Black Lives Matter Movement | Talk of the Town" />
      <Hero text="Debate" />
      <section className="single-page">
        <Container>
          <Row>
            <Col lg="12">
              <ButtonBack />
              {numberId}

              <h2 className="title">
                Black Lives Matter Movement
              </h2>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col lg="7">
              <h3>Vídeo</h3>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/4Vl4I0weXPU?rel=0"
                  allowFullScreen
                  title="Black Lives Matter Movement"
                />
              </div>
            </Col>
            <Col lg={{ size: 4, offset: 1 }}>
              <div className="widget">
                <h3>Informações</h3>
                <ul className="info">
                  <li>
                    <strong>Data: </strong>
                    <span>02 de agosto de 2020</span>
                  </li>
                  <li>
                    <strong>Hora: </strong>
                    <span>13h30</span>
                  </li>
                  <li>
                    <strong>Descrição:</strong>
                    <br />
                    <span>
                      O assassinato de George Floyd, um
                      homem negro dos Estados Unidos, por
                      um policial, durante uma abordagem,
                      gerou uma série de manifestações por
                      todo o país.
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <RegisterForm noSidebar title />
        </Container>
      </section>
    </Layout>
  )
}

const singlePage = () => (
  <Router>
    <ContentSinglePage path="/single/:id" />
  </Router>
)
export default singlePage
